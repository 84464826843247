<template>
  <div>
    <div
      id="loadingmessage"
      style="text-align:center;margin: 0px; padding: 0px; position: fixed; right: 0px; top: 0px; width: 100%; height: 100%; background-color: white; z-index: 30001; opacity: 0.8;"
      v-if="appLoading"
    >
      <p style="position: absolute; color: White; top: 40%; left: 50%;">
        <img src="../../assets/loader.gif" />
      </p>
    </div>
    <!-- <vue-element-loading :active="appLoading" :is-full-screen="false" /> -->
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="appConfirmation" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          <span style="font-size:16px;font-weight:600">Would you like to continue ?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00a972" text @click="appConfirmation = false">Cancel</v-btn>
          <v-btn color="red" text @click="changeMode">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout pb-2 wrap justify-center>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Add New Project</span>
                </v-flex>
              </v-layout>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="projectName"
                      v-model="projectName"
                      prepend-icon="mdi-projector-screen"
                      label="Project Name"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="location"
                      v-model="address"
                      prepend-icon="mdi-map-marker"
                      label="Address"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="location"
                      v-model="city"
                      prepend-icon="mdi-map-marker"
                      label="City"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="location"
                      v-model="state"
                      prepend-icon="mdi-map-marker"
                      label="State"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="location"
                      v-model="country"
                      prepend-icon="mdi-map-marker"
                      label="Country"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="category"
                      :items="categories"
                      item-text="name"
                      item-value="_id"
                      color="#26af82"
                      item-color="#26af82"
                      chips
                      label="Category"
                      prepend-icon="mdi-projector-screen"
                      multiple
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="openDatePicker"
                      v-model="openDatePicker"
                      :close-on-content-click="false"
                      :return-value.sync="openDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="openDate"
                          clearable
                          @click:clear="openDate = null"
                          label="Opening Date"
                          prepend-icon="mdi-calendar"
                          color="#26af82"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="#26af82"
                        :max="closeDate"
                        v-model="openDate"
                        no-title
                        scrollable
                        required
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="#26af82" @click="openDatePicker=false">Cancel</v-btn>
                        <v-btn color="#26af82" text @click="$refs.openDatePicker.save(openDate)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="closeDatePicker"
                      v-model="closeDatePicker"
                      :close-on-content-click="false"
                      :return-value.sync="closeDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="closeDate"
                          clearable
                          @click:clear="closeDate = null"
                          label="Closing Date"
                          prepend-icon="mdi-calendar"
                          color="#26af82"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="#26af82"
                        :min="openDate"
                        v-model="closeDate"
                        no-title
                        scrollable
                        required
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="#26af82" @click="closeDatePicker=false">Cancel</v-btn>
                        <v-btn
                          color="#26af82"
                          text
                          @click="$refs.closeDatePicker.save(closeDate)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12">
                    <label>Short Description</label>
                    <vue-editor
                      v-model="shortDescription"
                      ref="shortDescription"
                      prepend-icon="mdi-format-align-justify"
                      label="shortDescription"
                      color="#26af82"
                    ></vue-editor>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12">
                    <label>Description</label>
                    <vue-editor
                      v-model="description"
                      ref="description"
                      prepend-icon="mdi-format-align-justify"
                      label="Description"
                      color="#26af82"
                    ></vue-editor>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12">
                    <label>Investment Summary</label>
                    <vue-editor
                      v-model="investmentSummary"
                      ref="investmentSummary"
                      prepend-icon="mdi-format-align-justify"
                      label="InvestmentSummary"
                      color="#26af82"
                    ></vue-editor>
                  </v-col>
                </v-row>
                <div style="padding-top:20px;">
                  <v-flex xs12 md12 text-center>
                    <span style="font-size:20px;font-weight:500;">Financial Details</span>
                  </v-flex>
                </div>
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="fundingGoal"
                      v-model="fundingGoal"
                      prepend-icon="mdi-cash-multiple"
                      label="Funding Goal"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6">
                    <v-text-field
                      ref="dealType"
                      v-model="dealtype"
                      prepend-icon="mdi-cash-100"
                      label="Deal Type"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>-->
                  <!-- <v-col cols="12" sm="6">
                    <v-text-field
                      ref="currentReservation"
                      v-model="currentReservation"
                      prepend-icon="mdi-currency-usd"
                      label="Current Reservation"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>-->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="minimumReservation"
                      v-model="minimumReservation"
                      prepend-icon="mdi-currency-usd"
                      label="Minimum Reservation"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="maximumReservation"
                      v-model="maximumReservation"
                      prepend-icon="mdi-currency-usd"
                      label="Maximum Reservation"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      ref="termLength"
                      v-model="termLength"
                      prepend-icon="mdi-calendar-clock"
                      label="Term Length (Years)"
                      color="#26af82"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="12">
                    <v-flex text-center v-if="id == ''">
                      <v-btn
                        @click="handleSubmit"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add Project Details</v-btn>
                    </v-flex>
                    <v-flex text-center v-else>
                      <v-btn
                        @click="handleSubmit"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Update Project Details</v-btn>
                    </v-flex>
                  </v-col>
                </v-row>
              </v-container>
            </v-flex>
          </v-layout>
          <!-- projectpicture -->
          <v-layout wrap justify-space-around v-if="projectstep >0">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span
                    style="font-size:18px;font-weight:400"
                    v-if="profilepic == ''"
                  >Add Project Picture</span>
                  <span style="font-size:18px;font-weight:400" v-else>Update Project Picture</span>
                  <p style="font-size:12px">Hint: Image size should be maximum 5mb</p>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" v-if="profilepic != ''">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <img v-bind:src="baseURL+'/u/'+profilepic" height="auto" width="20%" />
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <input
                            ref="uploadPicture"
                            accept="image/*"
                            type="file"
                            @change="uploadPicture"
                          />
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="6" lg="12">
                      <v-flex text-center>
                        <v-btn
                          @click="submitPicture"
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                          width="250"
                        >Update Project Picture</v-btn>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-container>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- projectvideo -->
          <v-layout wrap justify-space-around v-if="projectstep >0">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400" v-if="media == ''">Add Project Video</span>
                  <span style="font-size:18px;font-weight:400" v-else>Update Project Video</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" v-if="media != ''">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <video width="320" height="240" controls>
                            <source v-bind:src="baseURL+'/u/'+media" type="video/mp4" />
                          </video>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <input
                            id="profvideo"
                            ref="uploadVideo"
                            accept="video/*"
                            type="file"
                            @change="uploadVideo"
                          />
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="6" lg="12">
                      <v-flex text-center>
                        <v-btn
                          @click="submitVideo"
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                          width="250"
                        >Update Project Video</v-btn>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-container>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- secondstep -->
          <v-layout wrap justify-space-around v-if="projectstep>0">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Investment Securities</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters v-for="(sec, index) in security" :key="index">
                    <v-col cols="12" sm="4">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            ref="name"
                            prepend-icon="mdi-cash-multiple"
                            v-model="sec.name"
                            label="Name"
                            color="#26af82"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-layout wrap justify-center>
                        <v-flex>
                          <v-select
                            :items="items"
                            label="Investment Type"
                            v-model="sec.investmenttype"
                            prepend-icon="mdi-projector-screen"
                            color="#26af82"
                            required
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-layout wrap justify-center>
                        <v-flex>
                          <v-text-field
                            ref="percentage"
                            v-model="sec.percentage"
                            prepend-icon="mdi-projector-screen"
                            label="Estimated Returns"
                            color="#26af82"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-layout wrap justify-center>
                        <v-flex>
                          <label>Short Description</label>
                          <vue-editor
                            v-model="sec.description"
                            ref="description"
                            prepend-icon="mdi-format-align-justify"
                            label="Description"
                            color="#26af82"
                          ></vue-editor>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-flex text-right>
                      <a
                        @click.prevent="security.splice(index, 1)"
                        class="text-capitalize"
                        width="250"
                      >
                        <i>Delete</i>
                      </a>
                    </v-flex>
                  </v-row>
                </v-container>

                <v-flex xs12 sm12 md12>
                  <v-layout wrap justify-left>
                    <v-flex text-left>
                      <a
                        @click="addInvestmentSecurity"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >
                        <i>Add More Investment Securities</i>
                      </a>
                    </v-flex>
                  </v-layout>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center v-if="security.length > 0">
                      <v-btn
                        rounded
                        small
                        dark
                        @click="submitSecurity"
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Update Investment Securities</v-btn>
                    </v-flex>
                    <v-flex text-center v-else>
                      <v-btn
                        rounded
                        small
                        dark
                        @click="submitSecurity"
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Submit Investment Securities</v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- thirdstep -->
          <v-layout wrap justify-space-around v-if="projectstep >1">
            <v-flex xs12 sm12 md12 pa-8>
              <v-layout wrap justify-space-around>
                <v-flex xs12 md12 pa-8>
                  <v-layout pt-5 wrap justify-center>
                    <v-flex text-center>
                      <span style="font-size:18px;font-weight:400">Metric Category</span>
                    </v-flex>
                  </v-layout>

                  <v-data-table
                    :headers="targetHeaders"
                    :items="targetMetrics"
                    :sort-desc="[false, true]"
                    hide-default-footer
                    dense
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    color="#26af82"
                  >
                    <template v-slot:item.metrics="{item}">
                      <a :href="baseURL+'/u/'+item.metrics" download target="_blank">
                        <span style="font-size:14px;font-weight:500;color:#000">{{item.metrics}}</span>
                      </a>
                    </template>
                    <template v-slot:item.commentry="{item}">
                      <a :href="baseURL+'/u/'+item.commentry" download target="_blank">
                        <span style="font-size:14px;font-weight:500;color:#000">{{item.commentry}}</span>
                      </a>
                    </template>
                    <template v-slot:item._id="{ item }">
                      <v-layout wrap justify-center>
                        <v-flex text-left>
                          <v-btn @click="editTargetMetric(item)" small color="blue" text>Edit</v-btn>
                        </v-flex>
                        <v-flex text-right>
                          <v-btn @click="deleteTargetMetric(item)" small color="red" text>Delete</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-data-table>

                  <v-layout pt-4 wrap px-2 justify-space-around>
                    <v-container>
                      <v-row v-if="addMetricShow" no-gutters>
                        <v-row no-gutters>
                          <v-col cols="12" sm="4">
                            <v-layout wrap justify-center>
                              <v-flex>
                                <v-menu
                                  ref="metricsDatePicker"
                                  v-model="metricsDatePicker"
                                  :close-on-content-click="false"
                                  :return-value.sync="metricsDate"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="metricsDate"
                                      clearable
                                      @click:clear="metricsDate = null"
                                      label="Metric Date"
                                      prepend-icon="mdi-calendar"
                                      color="#26af82"
                                      readonly
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    color="#26af82"
                                    v-model="metricsDate"
                                    no-title
                                    scrollable
                                    required
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="#26af82"
                                      @click="metricsDatePicker=false"
                                    >Cancel</v-btn>
                                    <v-btn
                                      color="#26af82"
                                      text
                                      @click="$refs.metricsDatePicker.save(metricsDate)"
                                    >OK</v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-layout wrap justify-center>
                              <v-flex>
                                <label for="metrics">Metrics</label>
                                <input
                                  type="file"
                                  id="metrics"
                                  required
                                  @change="onFileChangedMetrics"
                                />
                              </v-flex>
                            </v-layout>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-layout wrap justify-center>
                              <v-flex>
                                <label for="commentry">Commentry</label>
                                <input
                                  type="file"
                                  id="commentry"
                                  required
                                  @change="onFileChangedCommentry"
                                />
                              </v-flex>
                            </v-layout>
                          </v-col>
                        </v-row>
                      </v-row>
                      <v-btn
                        v-if="!addMetricShow"
                        text
                        small
                        color="#26af82"
                        @click="addMetricShow=true"
                        class="text-capitalize"
                      >Add New Metric</v-btn>
                    </v-container>
                    <v-flex xs12 sm12 md12>
                      <v-layout pt-4 wrap justify-center>
                        <v-flex text-center v-if="addMetricShow && editTargetMetricMode">
                          <v-btn
                            @click="editTargetMetricUpdate"
                            rounded
                            small
                            dark
                            :ripple="false"
                            class="text-capitalize"
                            color="#26af82"
                            width="250"
                          >Update Target Metrics</v-btn>
                        </v-flex>
                        <v-flex text-center v-if="addMetricShow && !editTargetMetricMode">
                          <v-btn
                            @click="submitTargetMetric"
                            rounded
                            small
                            dark
                            :ripple="false"
                            class="text-capitalize"
                            color="#26af82"
                            width="250"
                          >Add Target Metrics</v-btn>
                        </v-flex>
                        <v-flex text-center v-if="addMetricShow">
                          <v-btn
                            @click="cancelTargetMetricedit"
                            rounded
                            small
                            dark
                            :ripple="false"
                            class="text-capitalize"
                            color="red"
                            width="250"
                          >Cancel</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!-- keypartners -->
          <v-layout wrap justify-space-around v-if="projectstep >2">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Key Partners</span>
                </v-flex>
              </v-layout>
              <v-container>
                <v-row no-gutters v-for="part in partner" :key="part._id">
                  <v-col cols="12" sm="3">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <img v-bind:src="baseURL+'/u/'+part.logo" height="auto" width="50%" />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          ref="name"
                          v-model="part.name"
                          label="Partner name"
                          color="#26af82"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <input
                          type="file"
                          accept="image/*"
                          id="keyp"
                          required
                          @change="onFileChanged"
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <label>Description</label>
                        <vue-editor
                          v-model="part.description"
                          ref="description"
                          prepend-icon="mdi-format-align-justify"
                          label="Description"
                          color="#26af82"
                        ></vue-editor>
                      </v-flex>
                    </v-layout>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center style="margin-top:20px;margin-bottom:20px;">
                      <v-flex xs12 sm12 md12 center>
                        <v-btn
                          @click="updatePartners(part)"
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                        >Update Key Partners</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center style="margin-top:20px;margin-bottom:20px;">
                      <v-flex xs12 sm12 md12 center>
                        <v-btn
                          @click="deletePartners(part)"
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                        >Delete Key Partners</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          ref="name"
                          v-model="partnername"
                          label="Partner name"
                          color="#26af82"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <input
                          ref="uploadLogo"
                          id="keypart"
                          accept="image/*"
                          type="file"
                          @change="uploadLogo"
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <label>Description</label>
                        <vue-editor
                          v-model="partnerdescription"
                          ref="description"
                          prepend-icon="mdi-format-align-justify"
                          label="Description"
                          color="#26af82"
                        ></vue-editor>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center>
                      <v-btn
                        @click="addKeyPartners"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add Key Partners</v-btn>
                    </v-flex>
                  </v-layout>
                </v-row>
              </v-container>
            </v-flex>
          </v-layout>

          <!-- fundingdetails -->
          <v-layout wrap justify-space-around v-if="projectstep >3">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Financial Partners</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters v-for="(funding, index) in fundingdetail" :key="index">
                    <v-col cols="12" sm="4">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            ref="name"
                            v-model="funding.name"
                            label="Funding category"
                            color="#26af82"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <label>Description</label>
                          <vue-editor
                            v-model="funding.description"
                            ref="description"
                            prepend-icon="mdi-format-align-justify"
                            label="Description"
                            color="#26af82"
                          ></vue-editor>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-flex text-right>
                      <a
                        @click.prevent="fundingdetail.splice(index, 1)"
                        class="text-capitalize"
                        width="250"
                      >
                        <i>Delete</i>
                      </a>
                    </v-flex>
                  </v-row>
                </v-container>
                <v-flex xs12 sm12 md12>
                  <v-layout wrap justify-left>
                    <v-flex text-left>
                      <a
                        @click="addFundingDetails"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >
                        <i>Add More Funding Details</i>
                      </a>
                    </v-flex>
                  </v-layout>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center v-if="fundingdetail.length > 0">
                      <v-btn
                        @click="submitFunding"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Update Funding Details</v-btn>
                    </v-flex>
                    <v-flex text-center v-else>
                      <v-btn
                        @click="submitFunding"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add Funding Details</v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- Risks -->
          <v-layout wrap justify-space-around v-if="projectstep >4">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Risk and Disclosure</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters v-for="(risks, index) in risk" :key="index">
                    <v-col cols="12" sm="4">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            ref="name"
                            v-model="risks.name"
                            label="Risk type"
                            color="#26af82"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <label>Description</label>
                          <vue-editor
                            v-model="risks.description"
                            ref="description"
                            prepend-icon="mdi-format-align-justify"
                            label="Description"
                            color="#26af82"
                          ></vue-editor>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-flex text-right>
                      <a @click.prevent="risk.splice(index, 1)" class="text-capitalize" width="250">
                        <i>Delete</i>
                      </a>
                    </v-flex>
                  </v-row>
                </v-container>
                <v-flex xs12 sm12 md12>
                  <v-layout wrap justify-left>
                    <v-flex text-left>
                      <a
                        @click="addRisks"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >
                        <i>Add More Risks and Disclosures</i>
                      </a>
                    </v-flex>
                  </v-layout>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center v-if="risk.length > 0">
                      <v-btn
                        @click="submitRisks"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Update Risk and Disclosure</v-btn>
                    </v-flex>
                    <v-flex text-center v-else>
                      <v-btn
                        @click="submitRisks"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add Risk and Disclosure</v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- faq -->
          <v-layout wrap justify-space-around v-if="projectstep>5">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Project FAQ</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters v-for="(fa, index) in faq" :key="index">
                    <v-col cols="12" sm="12">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-textarea
                            ref="question"
                            v-model="fa.question"
                            label="Question"
                            color="#26af82"
                            required
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-textarea
                            ref="answer"
                            v-model="fa.answer"
                            label="Answer"
                            color="#26af82"
                            required
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-flex text-right>
                      <a @click.prevent="faq.splice(index, 1)" class="text-capitalize" width="250">
                        <i>Delete</i>
                      </a>
                    </v-flex>
                  </v-row>
                </v-container>
                <v-flex xs12 sm12 md12>
                  <v-layout wrap justify-left>
                    <v-flex text-left>
                      <a
                        @click="addFaq"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >
                        <i>Add More FAQ</i>
                      </a>
                    </v-flex>
                  </v-layout>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center v-if="faq.length > 0">
                      <v-btn
                        @click="submitfaq"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Update FAQ</v-btn>
                    </v-flex>
                    <v-flex text-center v-else>
                      <v-btn
                        @click="submitfaq"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add FAQ</v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap justify-space-around v-if="projectstep >7 ">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Terms and Conditions</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters v-for="(fa, index) in termsandconditions" :key="index">
                    <v-col cols="12" sm="12">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-textarea
                            ref="question"
                            v-model="fa.title"
                            label="Title"
                            color="#26af82"
                            required
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 md12>
                          <v-textarea
                            ref="answer"
                            v-model="fa.body"
                            label="Body"
                            color="#26af82"
                            required
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-flex text-right>
                      <a
                        @click.prevent="termsandconditions.splice(index, 1)"
                        class="text-capitalize"
                        width="250"
                      >
                        <i>Delete</i>
                      </a>
                    </v-flex>
                  </v-row>
                </v-container>
                <v-flex xs12 sm12 md12>
                  <v-layout wrap justify-left>
                    <v-flex text-left>
                      <a
                        @click="addTerms"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >
                        <i>Add More T&C</i>
                      </a>
                    </v-flex>
                  </v-layout>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center v-if="termsandconditions.length > 0">
                      <v-btn
                        @click="submitterms"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Update Terms and Conditions</v-btn>
                    </v-flex>
                    <v-flex text-center v-else>
                      <v-btn
                        @click="submitterms"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add Terms and Conditions</v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- Team Details -->
          <v-layout wrap justify-space-around v-if="projectstep >7 ">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Team</span>
                </v-flex>
              </v-layout>
              <v-container>
                <v-row no-gutters v-for="(item,i) in team" :key="i">
                  <v-col cols="12" sm="3">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <img v-bind:src="baseURL+'/u/'+item.photo" height="auto" width="50%" />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          ref="name"
                          v-model="item.name"
                          label="Name"
                          color="#26af82"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <label for="teampic">Picture</label>
                        <input
                          ref="onFileChangedTeam"
                          type="file"
                          accept="image/*"
                          id="teampic"
                          required
                          @change="onFileChangedTeam"
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <label>Description</label>
                        <vue-editor
                          v-model="item.description"
                          ref="description"
                          prepend-icon="mdi-format-align-justify"
                          label="Description"
                          color="#26af82"
                        ></vue-editor>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center style="margin-top:20px;margin-bottom:20px;">
                      <v-flex xs12 sm12 md12 center>
                        <v-btn
                          @click="updateTeam(item)"
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                        >Update Team Member</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center style="margin-top:20px;margin-bottom:20px;">
                      <v-flex xs12 sm12 md12 center>
                        <v-btn
                          @click="deleteTeam(item)"
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                        >Delete Team Member</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          ref="name"
                          v-model="teamName"
                          label="Name"
                          color="#26af82"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <input
                          ref="onFileChangedTeam"
                          accept="image/*"
                          id="teampic"
                          type="file"
                          @change="onFileChangedTeam"
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md12>
                        <label>Description</label>
                        <vue-editor
                          v-model="teamDescription"
                          ref="description"
                          prepend-icon="mdi-format-align-justify"
                          label="Description"
                          color="#26af82"
                        ></vue-editor>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center>
                      <v-btn
                        @click="addTeamMember"
                        rounded
                        small
                        dark
                        :ripple="false"
                        class="text-capitalize"
                        color="#26af82"
                        width="250"
                      >Add Team Member</v-btn>
                    </v-flex>
                  </v-layout>
                </v-row>
                <v-row no-gutters>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center>
                      <router-link :to="'/ProjectDocuments/'+projectName+'/'+id" target="_blank">
                        <v-btn
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                          width="250"
                        >Add documents</v-btn>
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-row>
                <v-row no-gutters>
                  <v-layout pt-4 wrap justify-center>
                    <v-flex text-center>
                      <router-link :to="'/ProjectProgress/'+projectName+'/'+id" target="_blank">
                        <v-btn
                          rounded
                          small
                          dark
                          :ripple="false"
                          class="text-capitalize"
                          color="#26af82"
                          width="250"
                        >Add progress to date</v-btn>
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-row>
              </v-container>
            </v-flex>
          </v-layout>
          <!-- Project Status -->
          <v-layout wrap justify-space-around v-if="projectstep > 7">
            <v-flex xs12 md12 pa-8>
              <v-layout pt-5 wrap justify-center>
                <v-flex text-center>
                  <span style="font-size:18px;font-weight:400">Change Project Status</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 wrap px-2 justify-space-around>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12" sm="12">
                      <p v-if="mode == 'Draft'">
                        <v-btn
                          @click="checkFinal()"
                          big
                          text
                          color="red"
                          block
                        >Click here to Publish Project</v-btn>
                      </p>
                      <p v-else>
                        <v-btn
                          @click="checkFinal()"
                          small
                          text
                          color="green"
                          block
                        >Click here to return project to DRAFT Mode</v-btn>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      msg: null,
      timeout: 10000,
      projectName: null,
      shortDescription: null,
      description: null,
      media: "",
      location: null,
      investmentSummary: null,
      fundingGoal: null,
      currentReservation: null,
      minimumReservation: null,
      maximumReservation: null,
      termLength: null,
      openDate: null,
      openDatePicker: false,
      closeDate: null,
      closeDatePicker: false,
      addedby: null,
      dealtype: null,
      createdDate: null,
      security: [],
      items: ["Realnumber", "Number", "Character"],
      targetMetricses: [],
      targetmetric: [],
      fundingdetail: [],
      risk: [],
      faq: [],
      partnername: "",
      partnerdescription: "",
      logo: null,
      logoname: null,
      logoimg: null,
      logosts: false,
      partnerId: null,
      partner: [],
      id: "",
      picture: null,
      projectstep: 0,
      profilepic: "",
      video: null,
      address: "",
      city: "",
      state: "",
      country: "",
      category: [],
      categories: [],
      mode: "",
      appConfirmation: false,
      termsandconditions: [],
      addMetricShow: false,
      metrics: null,
      commentry: null,
      metricsDate: null,
      metricsDatePicker: false,
      targetMetrics: [],
      editTargetMetricMode: false,
      editTargetMetricId: null,
      targetHeaders: [
        { text: "Metrics Date", value: "metricsDate" },
        { text: "Metrics", value: "metrics" },
        { text: "Commentry", value: "commentry" },
        { text: "Action", align: "center", value: "_id" }
      ],
      team: [],
      teamName: null,
      teamDescription: null,
      teamId: null,
      teamPic: null
    };
  },
  mounted() {
    if (this.id != "") {
      this.getTargetMetrics();
    }
    this.getCategory();
  },
  methods: {
    uploadVideo(event) {
      // if (event.target.files[0].size > 5242880) {
      //   this.msg = "Video Size be less than 5MB";
      //   this.showSnackBar = true;
      //   document.getElementById("profvideo").value = "";
      //   return;
      // } else {
      this.video = event.target.files[0];
      this.url = URL.createObjectURL(this.video);
      // }
    },
    uploadPicture(event) {
      this.picture = event.target.files[0];
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        return;
      }
      this.url = URL.createObjectURL(this.picture);
    },
    onFileChanged(event) {
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("keyp").value = "";
        return;
      } else {
        this.logo = event.target.files[0];
        this.url = URL.createObjectURL(this.logo);
      }
    },
    addTerms: function() {
      this.termsandconditions.push({
        title: null,
        body: null
      });
    },
    addFaq: function() {
      this.faq.push({
        question: null,
        answer: null
      });
    },
    addRisks: function() {
      this.risk.push({
        name: null,
        description: null
      });
    },
    addFundingDetails: function() {
      this.fundingdetail.push({
        name: null,
        description: null
      });
    },
    addOther: function() {
      this.targetmetric[this.targetmetric.length - 1].category.push({
        name: null,
        datatype: null,
        baselinevalue: null,
        targetvalue: null,
        description: null
      });
    },
    addNew: function() {
      this.targetmetric.push({
        categoryid: null,
        category: [
          {
            name: null,
            datatype: null,
            baselinevalue: null,
            targetvalue: null,
            description: null
          }
        ]
      });
    },
    getTargetMetrics: function() {
      axios({
        method: "GET",
        url: "/TargetMetric/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          projectid: this.projectId
        }
      })
        .then(response => {
          this.targetMetricses = response.data.data;
        })
        .catch(err => {
          this.msg = err;
        });
    },
    getCategory: function() {
      axios({
        method: "GET",
        url: "/project/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(err => {
          this.msg = err;
        });
    },
    addInvestmentSecurity: function() {
      this.security.push({
        name: null,
        investmenttype: null,
        percentage: null,
        description: null
      });
    },
    loadData: function() {
      var vm = this;
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/get",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          vm.appLoading = false;
          if (response.data.status) {
            vm.targetmetric = [];
            vm.security = [];
            vm.fundingdetail = [];
            vm.risk = [];
            vm.faq = [];
            vm.partner = [];
            vm.team = response.data.data.team;
            vm.termsandconditions = [];
            var data = response.data.data;
            this.targetMetrics = response.data.data.metrics;
            for (var h = 0; h < this.targetMetrics.length; h++) {
              var dt = new Date(this.targetMetrics[h].metricsDate);
              dt = dt.toString();
              dt = dt.slice(4, 15);
              this.targetMetrics[h].metricsDate = dt;
            }
            vm.projectstep = data.projectstep;
            vm.projectName = data.name;
            vm.shortDescription = data.shortDescription;
            vm.description = data.description;
            vm.mode = data.mode;
            if (data.media != undefined) {
              vm.media = data.media;
            }
            vm.category = data.category;
            vm.location = data.location;
            vm.address = data.address;
            vm.city = data.city;
            vm.state = data.state;
            vm.country = data.country;
            vm.investmentSummary = data.investmentSummary;
            vm.fundingGoal = data.fundingGoal;
            vm.currentReservation = data.currentReservation;
            vm.minimumReservation = data.minimumReservation;
            vm.maximumReservation = data.maximumReservation;
            vm.dealtype = data.dealtype;
            if (data.logo != undefined) {
              vm.profilepic = data.logo;
            }
            vm.openDate = data.openDate.slice(0, 10);
            vm.closeDate = data.closeDate.slice(0, 10);
            vm.createdDate = data.createdDate;
            if (data.security.length > 0) {
              data.security.forEach(function(r) {
                vm.security.push({
                  name: r.name,
                  investmenttype: r.investmenttype,
                  percentage: r.percentage,
                  description: r.description,
                  _id: r._id
                });
              });
            }

            if (data.targetmetric.length > 0) {
              data.targetmetric.forEach(function(r) {
                vm.targetmetric.push({
                  categoryid: r.categoryid,
                  _id: r._id,
                  category: []
                });
              });
              for (var i = 0; i < data.targetmetric.length; i++) {
                data.targetmetric[i].category.forEach(function(r) {
                  vm.targetmetric[i].category.push({
                    name: r.name,
                    datatype: r.datatype,
                    baselinevalue: r.baselinevalue,
                    targetvalue: r.targetvalue,
                    description: r.description,
                    _id: r._id
                  });
                });
              }
            }
            if (data.fundingdetail.length > 0) {
              data.fundingdetail.forEach(function(r) {
                vm.fundingdetail.push({
                  name: r.name,
                  description: r.description,
                  _id: r._id
                });
              });
            }
            if (data.risk.length > 0) {
              data.risk.forEach(function(r) {
                vm.risk.push({
                  name: r.name,
                  description: r.description,
                  _id: r._id
                });
              });
            }
            if (data.faq.length > 0) {
              data.faq.forEach(function(r) {
                vm.faq.push({
                  question: r.question,
                  answer: r.answer,
                  _id: r._id
                });
              });
            }
            if (data.partner.length > 0) {
              data.partner.forEach(function(r) {
                vm.partner.push({
                  name: r.name,
                  description: r.description,
                  _id: r._id,
                  logo: r.logo
                });
              });
            }
            if (data.termsandconditions.length > 0) {
              data.termsandconditions.forEach(function(r) {
                vm.termsandconditions.push({
                  title: r.title,
                  body: r.body,
                  _id: r._id
                });
              });
            }
          } else {
            vm.msg = response.data.msg;
            // vm.showSnackBar = true;
          }
        })
        .catch(err => {
          vm.appLoading = false;
          vm.ms = err.response;
          vm.msg = "Server Unreachable";
          //   vm.showSnackBar = true;
        });
    },
    handleSubmit: function() {
      this.appLoading = true;
      var data = {};
      data["name"] = this.projectName;
      data["shortDescription"] = this.shortDescription;
      data["description"] = this.description;
      data["media"] = this.media;
      data["address"] = this.address;
      data["city"] = this.city;
      data["state"] = this.state;
      data["country"] = this.country;
      data["investmentSummary"] = this.investmentSummary;
      data["fundingGoal"] = this.fundingGoal;
      data["minimumReservation"] = this.minimumReservation;
      data["maximumReservation"] = this.maximumReservation;
      data["durationOfInvestment"] = this.termLength;
      data["openDate"] = this.openDate;
      data["closeDate"] = this.closeDate;
      data["category"] = this.category;
      if (this.id != "") {
        data["id"] = this.id;
      }
      axios({
        method: "POST",
        url: "/project/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.id != undefined) {
              this.id = response.data.id;
              this.loadData();
              this.getTargetMetrics();
            } else {
              this.getTargetMetrics();
              this.loadData();
            }
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitPicture: function() {
      let formData = new FormData();
      formData.append("logo", this.picture);
      formData.append("id", this.id);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/project/logo",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.loadData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitVideo: function() {
      let formData = new FormData();
      formData.append("media", this.video);
      formData.append("id", this.id);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/project/media",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.loadData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitSecurity: function() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.security;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/projectsecurity/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.loadData();
            this.getTargetMetrics();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    // ///////////////////////////////////// Target Metrics /////////////////////////////////

    onFileChangedMetrics(event) {
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("metrics").value = "";
        return;
      } else {
        this.metrics = event.target.files[0];
      }
    },
    onFileChangedCommentry(event) {
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("commentry").value = "";
        return;
      } else {
        this.commentry = event.target.files[0];
      }
    },
    submitTargetMetric() {
      this.appLoading = true;
      let formData = new FormData();
      formData.append("metrics", this.metrics);
      formData.append("commentry", this.commentry);
      formData.append("metricsDate", this.metricsDate);
      formData.append("projectid", this.id);

      axios({
        method: "POST",
        url: "/Metrics/add",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.metrics = null;
          this.commentry = null;
          this.metricsDate = null;
          document.getElementById("metrics").value = "";
          document.getElementById("commentry").value = "";
          this.addMetricShow = false;
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.loadData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    deleteTargetMetric(item) {
      this.appLoading = true;
      var data = {};
      data["id"] = item._id;
      axios({
        method: "POST",
        url: "/Metrics/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.loadData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    cancelTargetMetricedit() {
      this.editTargetMetricMode = false;
      this.metrics = null;
      this.commentry = null;
      this.metricsDate = null;
      this.editTargetMetricId = null;
      document.getElementById("metrics").value = "";
      document.getElementById("commentry").value = "";
      this.addMetricShow = false;
    },
    editTargetMetric(item) {
      this.editTargetMetricMode = true;
      this.editTargetMetricId = item._id;
      this.addMetricShow = true;
      const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];
      var dt = new Date(item.metricsDate);
      var mth = months[dt.getMonth()];
      var yr = dt.getFullYear();
      var day = dt.getDate();
      var newmonth = yr + "-" + mth + "-" + day;
      this.metricsDate = newmonth;
      // this.metrics = item.metrics;
      // this.commentry = item.commentry;
    },
    editTargetMetricUpdate() {
      this.appLoading = true;
      let formData = new FormData();
      formData.append("metrics", this.metrics);
      formData.append("commentry", this.commentry);
      formData.append("metricsDate", this.metricsDate);
      formData.append("projectid", this.id);
      formData.append("id", this.editTargetMetricId);
      var newURL;
      var editData = {};
      editData["id"] = this.editTargetMetricId;
      editData["metricsDate"] = this.metricsDate;
      if (this.metrics && this.commentry) {
        newURL = "/Metrics/edit";
        editData = formData;
      } else {
        newURL = "/Metrics/editdate";
      }
      axios({
        method: "POST",
        url: newURL,
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: editData
      })
        .then(response => {
          this.appLoading = false;
          if (this.metrics && !this.commentry) {
            this.singleTargetMetricsUpload("metrics");
          } else if (this.commentry && !this.metrics) {
            this.singleTargetMetricsUpload("commentry");
          } else {
            this.editTargetMetricId = null;
            this.metrics = null;
            this.commentry = null;
            document.getElementById("metrics").value = "";
            document.getElementById("commentry").value = "";
            this.metricsDate = null;
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
          this.editTargetMetricMode = false;
          this.addMetricShow = false;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    singleTargetMetricsUpload(type) {
      this.appLoading = true;
      let formData = new FormData();
      if (type == "metrics") {
        formData.append("doc", this.metrics);
      } else {
        formData.append("doc", this.commentry);
      }
      formData.append("name", type);
      formData.append("metricsDate", this.metricsDate);
      formData.append("id", this.editTargetMetricId);
      axios({
        method: "POST",
        url: "/Metrics/editsingle",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.metrics = response;
          this.metrics = null;
          this.commentry = null;
          this.metricsDate = null;
          this.loadData();
          // document.getElementById("metrics").value = "";
          // document.getElementById("commentry").value = "";
          this.editTargetMetricMode = false;
          this.addMetricShow = false;
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    //////////////////////////////////////////////////////  End of Target Metrics //////////////////////
    submit: function() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.targetmetric;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/TargetMetric/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitFunding: function() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.fundingdetail;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/Funding/addall/",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitRisks() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.risk;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/Risk/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitfaq() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.faq;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/projectfaq/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    submitterms: function() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.termsandconditions;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/projectconditions/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.loadData();
            this.showSnackBar = true;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.appLoading = false;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    uploadLogo: function(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("keypart").value = "";
        return;
      }
      this.logo = e.target.files[0];
      this.logoname = e.target.files[0].name;
      var reader = new FileReader();
      reader.onload = e => {
        this.logoimg = e.target.result;
      };
      this.logosts = true;
      reader.readAsDataURL(files[0]);
    },
    addKeyPartners: function() {
      var data = {};
      data["name"] = this.partnername;
      data["description"] = this.partnerdescription;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/partner/add",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.partnerId = response.data.id;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.partnername = null;
            this.partnerdescription = null;
            if (this.logosts) {
              this.upLogo();
            } else {
              this.loadData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    upLogo: function() {
      let formData = new FormData();
      formData.append("logo", this.logo);
      formData.append("id", this.partnerId);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/partner/logo",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.logo = null;
            this.logoname = null;
            this.logoimg = null;
            this.logosts = false;
            this.selectedFile = "";
            this.file = null;
            document.getElementById("keypart").value = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    updatePartners: function(item) {
      var data = {};
      data["name"] = item.name;
      data["description"] = item.description;
      data["id"] = item._id;
      axios({
        method: "POST",
        url: "/partner/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.partnerId = item._id;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            if (this.logo != null) {
              this.upLogo();
            } else {
              this.loadData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    deletePartners: function(item) {
      this.appLoading = true;
      var data = {};
      data["id"] = item._id;
      axios({
        method: "POST",
        url: "/partner/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.loadData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    checkFinal: function() {
      this.appConfirmation = true;
    },
    changeMode: function() {
      this.appConfirmation = false;
      this.appLoading = true;
      var data = {};
      data["id"] = this.id;
      if (this.mode == "Draft") {
        data["mode"] = "Publish";
      } else {
        data["mode"] = "Draft";
      }
      axios({
        method: "POST",
        url: "/project/status",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.loadData();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },

    onFileChangedTeam(event) {
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("metrics").value = "";
        return;
      } else {
        this.teamPic = event.target.files[0];
      }
    },

    addTeamMember() {
      var data = {};
      data["name"] = this.teamName;
      data["description"] = this.teamDescription;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/team/add",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.teamId = response.data.id;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.teamName = null;
            this.teamDescription = null;
            if (this.teamPic) {
              this.uploadTeamImage();
            } else {
              this.loadData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    uploadTeamImage() {
      let formData = new FormData();
      formData.append("photo", this.teamPic);
      formData.append("id", this.teamId);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/team/photo",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.teamPic = null;
            document.getElementById("teampic").value = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },

    updateTeam(item) {
      var data = {};
      data["name"] = item.name;
      data["description"] = item.description;
      data["id"] = item._id;
      data["projectid"] = this.id;
      this.teamId = item._id;
      axios({
        method: "POST",
        url: "/team/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.partnerId = item._id;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            if (this.teamPic != null) {
              this.uploadTeamImage();
            } else {
              this.loadData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    deleteTeam(item) {
      this.appLoading = true;
      var data = {};
      data["id"] = item._id;
      axios({
        method: "POST",
        url: "/team/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.loadData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
.v-input {
  padding-left: 10px;
  padding-right: 10px;
}
</style>